/**
 * @author wangsl
 */
import WX from '@xt/client/mixins/login/weixin'
import { Observer } from 'mobx-vue'
import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { AjaxBasics } from '../../helpers'
import { EnumOrderStateTypeNumber } from '../../enums'
import { EnumVerifyState } from '@xt/client/entities'

@Observer
@Component
export default class GroupBuy extends Vue {
  @Prop({}) platform: string // 'web' || 'wap'
  @Prop({}) dataSource: any
  // 控制是否是待支付订单（具体字段不知道啥意思）
  payOrigin: boolean = false

  /**
   * 倒计时的时分秒
   * 初始化皆为00，获取数据时计算其值
   */
  h: string = '00'
  m: string = '00'
  s: string = '00'
  loading: boolean = true // 页面是否加载中
  resetRefresh: boolean = false
  endTime: number = 0 // 拼团的结束时间，通过这个值计算倒计时
  productId: string // 商品Id
  groupNo: string // 团购编号
  detail: any = {} // 拼团详情数据存储在这里
  downtimeTimer: NodeJS.Timer // 倒计时的定时器，退出页面时需要销毁
  refreshTimer: NodeJS.Timer // 自动刷新页面的定时器，退出页面时需要销毁
  visibleCheckPopup: boolean = false // 是否显示校验弹窗
  visiblePosterPopup: boolean = false // 是否显示海报
  cancelBtnState: boolean = false // 取消订单防止重复点击
  visibleSignedAlert: boolean = false // 是否显示报名后弹窗
  visibleWxbind: boolean = false // 是否显示绑定微信弹窗
  visibleAgreement: boolean = false // 是否显示协议弹窗
  visibleCollagePopup: boolean = false // 是否显示拉手规则弹窗
  visibleSharePopup: boolean = false // 是否显示手机端点击分享按钮的弹窗
  toPlaceOrderStatus: boolean = false // 去下单功能的拦截，防止连续点击，也可修改该属性禁用功能，默认不拦截
  // 待支付校验弹窗的数据
  checkPopupData: any = {
    mainHandler: () => {}, // 初始化事件处理函数
    viceHandler: () => {} // 初始化事件处理函数
  }
  agrDataSource: any = {}
  frequently: { count: number; endTime: number } = { count: 0, endTime: 0 }
  isMyCreate: boolean = false // 这个拼团是非拼团人默认为false
  showDownTime: boolean = false // 是否要显示倒计时
  agrHandle: () => void = () => {} // 报名协议的确认事件

  /**
   * 0待成团，1拼团成功，2拼团失败
   */
  get groupStatus() {
    return this.detail.status
  }

  get groupStore() {
    return this.$store.$storeGroup
  }

  get userStore() {
    return this.$store.$storeUser
  }

  get contentType() {
    return (
      {
        1: '书法',
        2: '读书',
        3: '写作',
        4: '养生',
        5: '国画',
        6: '实物',
        7: '书画'
      }[parseInt(this.detail.contentType)] || '未知'
    )
  }

  get money() {
    let yuan = (this.detail.groupPrice || 0) / 100
    return yuan % 1 != 0 ? yuan.toFixed(2) : yuan
  }

  get coverUrl() {
    return `${this.detail.coverUrl}?x-oss-process=image/resize,w_200,h_200,m_fill/quality,q_90`
  }

  get coverUrlH5() {
    return `${this.detail.coverUrlH5}?x-oss-process=image/resize,w_200,h_200,m_fill/quality,q_90`
  }

  get isWechatBowser() {
    return this.$store.$global.WechatBowser
  }

  /**
   * 校验是否登录
   */
  isLogin() {
    return this.$store.$storeUser.loggedIn
  }

  /**
   * 校验是否绑定微信
   */
  isWxBind() {
    return this.$store.$storeUser.isWxBind
  }

  /**
   * 校验是否绑定手机号或邮箱
   */
  isBindPhoneOrEmail() {
    return !this.$store.$storeUser.needBindDevice
  }

  back() {
    if (this.$store.$global.isEmbeddedH5Page) return
    if (this.isEmptyRouter()) return this.$router.push({ name: 'index' })
    this.$router.back()
  }

  showWxbind() {
    this.visibleWxbind = true
  }

  showPosterPopup() {
    this.visiblePosterPopup = true
  }

  showSignedAlert() {
    this.visibleSignedAlert = true
  }

  get pageClass() {
    return this.detail.status === 1 && this.detail.canGroup ? 'groupbuy-box-bottom' : 'groupbuy-box-bottom-nopopup'
  }

  showAgreement() {
    this.visibleAgreement = true
  }

  showCollagePopup() {
    this.visibleCollagePopup = true
  }

  showSharePopup() {
    this.visibleSharePopup = true
  }

  /**
   * 显示待支付订单校验的弹窗
   */
  showCheckPopup() {
    this.visibleCheckPopup = true
  }

  /**
   * 倒计时的实现
   */
  downtime() {
    this.downtimeTimer = setInterval(() => {
      let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
      let diff = this.endTime - currentTime
      let toZero = diff <= 0 || this.dataSource.status === 2
      // Thu Jan 01 1970 08:00:00 GMT+0800 (中国标准时间) {} 所以 - 28800000
      const [h, m, s] = toZero
        ? ['00', '00', '00']
        : moment(diff - 28800000)
            .format('HH:mm:ss')
            .split(':')
      this.h = h
      this.m = m
      this.s = s
      if (toZero) {
        clearInterval(this.downtimeTimer)
        if (this.resetRefresh) {
          // Q: 因为后端数据误差这里区分用户对状态切换左对应处理(具体信息看Created函数中的数据修改注释)
          // A: 如果当前用户是团长则拼团结束时间35分钟后切换状态为失效（刷新的方式）
          if (this.isMyCreate)
            this.refreshTimer = setTimeout(() => {
              window.location.href = window.location.href
            }, diff + 2100000)
          // A: 如果是其他用户则立即切换状态为失效（刷新的方式）
          else window.location.href = window.location.href
        }
      }
    }, 200)
  }

  /**
   * 个人角度活动状态
   */
  get status() {
    let { detail, groupStatus } = this
    switch (true) {
      // 101 待成团且是拼团人
      case groupStatus === 0 && detail.canGroup:
        return 101
      // 102 待成团非拼团人
      case groupStatus === 0 && !detail.canGroup:
        return 102
      // 103 拼团成功且是拼团人
      case groupStatus === 1 && detail.canGroup:
        return 103
      // 104 拼团成功非拼团人
      case groupStatus === 1 && !detail.canGroup:
        return 104
      // 105 拼团失败且是拼团人
      case groupStatus === 2 && detail.canGroup:
        return 105
      // 106 拼团失败非拼团人
      case groupStatus === 2 && !detail.canGroup:
        return 106
      default:
        return 0
    }
  }

  /**
   * 去往下单页面
   * @param group 是否团购 default:true
   * @param create 是否创建拼团 default: false
   * @returns
   */
  async toPlaceOrder(agrHandle: () => void) {
    this.agrHandle = agrHandle.bind(this)
    this.visibleAgreement = true
  }

  /**
   * 校验
   * 用户状态
   * 订单状态
   * 团购状态等
   */
  async check2Api(create: boolean = false) {
    const { groupNo, productId } = this
    return await this.$ajax.get('/order/api/orders/check/{productId}', {
      groupNo: !create ? groupNo : '',
      productId,
      group: true
    })
  }

  /**
   * 综合校验
   */
  async check(group: boolean = true, create: boolean = false, verifyWechat: boolean = true) {
    if (this.frequently.count === 5) return (this.frequently.endTime = Date.now() + 5 * 1000)
    if (this.frequently.count >= 5 && this.frequently.endTime >= Date.now()) return this.$msg('操作频繁，等一会吧~')
    if (this.frequently.count >= 5 && this.frequently.endTime < Date.now()) this.frequently.count = 0
    if (this.toPlaceOrderStatus) return
    this.toPlaceOrderStatus = true // 防止多次点击
    // 调用接口前的前置校验
    switch (false) {
      // 校验是否登录
      case this.isLogin():
        this.toPlaceOrderStatus = false
        if (this.$store.$global.isEmbeddedH5Page) {
          wx.miniProgram.navigateTo({
            url: `/pages/login/index?redirect=${encodeURIComponent(window.location.href)}`
          })
          return
        }
        this.$store.$storeUser.onToggleVisible(true, window.location.href)
        return
      // // 校验是否绑定微信
      case this.isWxBind() || this.isShow:
        this.toPlaceOrderStatus = false
        this.bindWx()
        return
      // 校验是否绑定手机号或邮箱
      case this.isBindPhoneOrEmail():
        this.toPlaceOrderStatus = false
        this.platform === 'web'
          ? this.$store.$storeUser.onToggleModal(this.$EnumLocaleLinks.links_bind_device)
          : this.$router.push({ name: 'other-bind' })
        return
      // 校验是否需要完善个人信息
      case !this.$store.$storeUser.onneedCompleteInfo():
        this.toPlaceOrderStatus = false
        this.$store.$storeUser.onToggleModal(this.$EnumLocaleLinks.links_complete)
        return
    }
    try {
      let apiRes: any = await this.check2Api(create)
      const currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
      switch (true) {
        // 已绑定微信  (是否需要检验微信 & 首次购买日课 & 校验时间大于7天展示检验弹窗)
        case verifyWechat &&
          !this.isShow &&
          !apiRes.haveBuyActivity &&
          currentTime > moment(this.userStore.wxCheckTime).add(7, 'days').valueOf():
          this.toPlaceOrderStatus = false
          this.verifyWx(group, create)
          return
        case !!apiRes.oldOrder: // 是否有待支付订单
          this.toPlaceOrderStatus = false
          this.popOrderChoose(apiRes, group, create)
          return
        // 后端返回用户未绑定微信，根据商品类型区分是否需要绑定弹窗
        case !apiRes.ownerWx && !this.isShow:
          this.toPlaceOrderStatus = false
          this.bindWx()
          return
        default:
          this.toPlaceOrderStatus = false
          // 参团人首次进入 & 拼团人的订单状态是退款中
          if (!create && apiRes.groupOrderStatus === EnumOrderStateTypeNumber.Refund) {
            this.$msg('拉手团已取消')
            return
          }
          this.toPlaceOrder(() => this.checkedToPayment({}, false, group, create))
      }
    } catch (_) {
      this.toPlaceOrderStatus = false
      this.frequently.count += 1
      // 其他异常情况的处理
    }
  }

  /**
   * 绑定微信
   * 根据不同端做相应处理
   */
  bindWx() {
    if (this.platform === 'web') {
      // 如果是PC端
      this.userStore.showWeiXinBind({
        title: '去绑定',
        content: '当前课程需使用微信登录暄桐日课小程序中学习，购课前请绑定微信。 购买课程后，将不再支持微信换绑和解绑。',
        scanTip: '扫描小程序码绑定微信'
      })
    } else {
      // 如果是手机端
      if (!this.isWechatBowser) {
        // 手机端但不是微信内
        // 提示请在微信内操作
        return this.$msg('请在微信内操作')
      }
      this.userStore.showWechatVerify({
        title: '微信绑定',
        content: '当前课程需使用微信登录暄桐日课小程序中学习，购课前请绑定微信。\n购买课程后，将不再支持微信换绑和解绑。',
        type: 'bind'
      })
    }
  }

  /**
   * 检验微信
   * 根据不同端做相应处理
   */
  verifyWx(group: boolean, create: boolean) {
    if (this.platform === 'web') {
      this.userStore.showWeiXinVerify({
        title: '微信验证',
        content: '当前课程需使用账号绑定的微信登录暄桐日课小程序中学习。 购买课程后，将不再支持微信换绑和解绑。',
        scanTip: '扫码验证绑定微信与使用微信是否一致',
        operation: true,
        skipHandler: () => {
          this.userStore.hideWeiXinVerify()
          this.check(group, create, false)
        },
        signupHandler: () => {
          this.userStore.hideWeiXinVerify()
          this.check(group, create, false)
        }
      })
    } else {
      this.userStore.showWechatVerify({
        title: '微信验证',
        content: '当前课程需使用账号绑定的微信登录暄桐日课小程序中学习。购买课程后，将不再支持微信换绑和解绑。',
        type: 'verify',
        tip: '点击验证绑定微信与使用微信是否一致',
        status: EnumVerifyState.NoVerify,
        skipHandler: () => {
          this.userStore.hideWechatVerify()
          this.check(group, create, false)
        }
      })
    }
  }

  /**
   * @param errorData 检验异常拿到的数据
   * @param before 继续上一订单 - 创建新订单
   * @param group 是否团购
   */
  checkedToPayment(errorData: any, before: boolean = false, group: boolean = false, create: boolean = false) {
    const query = Object.assign(
      // 如果是团购且不是自己创建则传递groupNo
      // 如果是支付原订单传递原订单groupNo，创建新订单则传递新的groupNo
      group && !create && (before ? errorData.groupNo : this.groupNo) ? { groupNo: before ? errorData.groupNo : this.groupNo } : {},
      // 如果继续上一订单则传递检验数据中的商品Id反之则传递路由中商品ID（没啥意义）
      { productId: before ? errorData.productId : this.productId },
      // 如果本次下单是团购且历史订单是团购传递订单编号
      // 如果本次下单不是团购历史订单不是团购且继续支付上一订单则传递订单编号
      (group && errorData.activityType === 1) || (!group && errorData.activityType === 0 && before)
        ? { orderNo: errorData.oldOrderNo }
        : {},
      // 是否下单拼团
      { group: group.toString() },
      // group  ? { group: (true).toString() } : { group: (false).toString() },  用上边代替
      // 用户上一步的操作，支付原订单则传递true 反之不传递
      // before ? { memberBeforeOption: (true).toString() } : {},  用下边代替
      { memberBeforeOption: this.payOrigin.toString() }
    )

    if (this.$store.$global.isEmbeddedH5Page) {
      const searchParam = new URLSearchParams()
      Object.keys(query).forEach(key => {
        searchParam.set(key, `${query[key]}`)
      })

      console.log(searchParam.toString())

      wx.miniProgram.navigateTo({
        url: `/pages/payment/index?${searchParam.toString()}`
      })
      return
    }

    const router = { name: 'payment', query, params: {} }
    // 微信内无openid 携带Code跳转
    if (this.$store.$global.WechatBowser && this.$store.$global.platform === 'Mobile' && !this.$store.$storeUser.wxOpenid)
      return window.location.replace(
        this.$store.$wechat.getAuthorizeUrl(window.location.origin + this.$router.resolve(router).href, 'snsapi_base')
      )

    this.$router.push(router)
  }

  /**
   * 显示待支付订单弹窗
   */
  popOrderChoose(errorData: any, group: boolean = true, create: boolean = false) {
    const { oldOrderNo, payAmount, virtualCurrency } = errorData
    switch (true) {
      case errorData.activityType === 0: // 单独购买
        this.checkPopupData = {
          title: `你已点过【一人学】啦，但还没有支付`,
          waitPrice: payAmount,
          count: virtualCurrency / 100 / 8,
          ratio: 8,
          mainText: '取消订单，重新报名',
          viceText: '继续支付',
          mainHandler: () =>
            this.toPlaceOrder(async () => {
              if (!this.cancelBtnState) {
                try {
                  this.payOrigin = false
                  this.cancelBtnState = true
                  await this.$store.$storeOrder.onOrderCancel({ orderNo: oldOrderNo })
                  this.checkedToPayment(errorData, false, group, create)
                } catch (_) {
                  this.cancelBtnState = false
                }
              }
            }),
          viceHandler: () => {
            this.payOrigin = true
            this.checkedToPayment(errorData, true, false, create)
          }
        }
        break
      case !!errorData.groupNo: // 接受了好友的拼团
        this.checkPopupData = {
          mainHeader: errorData.groupMemberHeaderUrl,
          title: '你已接受好友的【拉手团】邀请，但还没有支付',
          waitPrice: payAmount,
          count: virtualCurrency / 100 / 8,
          ratio: 8,
          mainText: '继续支付',
          viceText: '取消订单，重新报名',
          mainHandler: () => {
            this.payOrigin = true
            this.checkedToPayment(errorData, true, true, create)
          },
          viceHandler: () =>
            this.toPlaceOrder(() => {
              this.payOrigin = false
              this.checkedToPayment(errorData, false, group, create)
            })
        }
        break
      default: // 自己创建了拼团
        this.checkPopupData = {
          title: '你已发起【拉手团】啦，但还没有支付',
          waitPrice: payAmount,
          count: virtualCurrency / 100 / 8,
          ratio: 8,
          mainText: '取消订单，重新报名',
          viceText: '继续支付',
          mainHandler: () =>
            this.toPlaceOrder(() => {
              this.payOrigin = false
              this.checkedToPayment(errorData, false, group, create)
            }),
          viceHandler: () => {
            this.payOrigin = true
            this.checkedToPayment(errorData, true, true, create)
          }
        }
    }
    this.showCheckPopup()
  }

  /**
   * 去往普通课程的详情页
   */
  toCourseDetail() {
    this.$router.push({ path: `/commodity/${this.productId}` })
  }

  /**
   * 去往日课详情页
   */
  toDayClassDetail() {
    this.$router.push({ path: `/commodity/${this.productId}` })
  }

  toShowDetail() {
    this.$router.push({ path: `/commodity/${this.productId}` })
  }

  /**
   * 检验不同商品类型去往对应的详情页
   */
  toProductDetail() {
    switch (this.detail.productType) {
      case 201001: // 单阶课
        this.toCourseDetail()
        break
      case 201002: // 全阶课
        this.toCourseDetail()
        break
      case 202001: // 教练日课
        this.toDayClassDetail()
        break
      case 203001: // 线上展览
        this.toShowDetail()
        break
      case 203002: // 线下展览
        this.toShowDetail()
        break
      default: // 以后可能出现的其他商品类型
        throw new Error('未定义的商品类型')
    }
  }

  get isShow() {
    return this.detail.productType === 203001 || this.detail.productType === 203002
  }

  /**
   * 销毁前清理
   */
  beforeDestroy() {
    clearInterval(this.downtimeTimer)
    clearTimeout(this.refreshTimer)
  }
  /**
   * 复制当前页面地址
   */
  copyHref() {
    const disappear = 'position: fixed;z-index: -2;top: -100px'
    let input = document.createElement('input')
    input.value = window.location.href
    input.setAttribute('style', disappear)
    document.body.appendChild(input)
    input.focus()
    input.select()
    let b = document.execCommand('copy', false, null)
    document.body.removeChild(input)
    return b
  }

  /**
   * 点击分享给好友
   */
  share() {
    // 如果是手机端且是微信浏览器，走微信分享
    if (this.platform !== 'web' && this.isWechatBowser) {
      this.showSharePopup()
    } else {
      let copy = this.copyHref()
      // 复制成功提示
      if (copy) this.$msg('活动链接已复制', 'success')
      // 如果复制失败则唤起海报
      if (!copy) this.showPosterPopup()
    }
  }

  async created() {
    // 异步加载所有所需图片， 预加载
    this.$store.$storeGroup.loaImgAll()
    // 读取params参数，全局存储
    const { groupNo } = this.$route.params
    this.groupNo = groupNo // 拼团编号

    let isNeedBind: boolean = await WX.wxBind()
    let isNeedLogin: boolean = await WX.wxLogin()
    // 如果需要绑定或者需要登录则成功后再次读取信息
    let detail: any = isNeedBind || isNeedLogin ? await this.$store.$storeGroup.getGroupDetail(groupNo) : this.dataSource

    this.productId = detail.productId // 商品Id

    // 如果相应信息中包含groupNo字段认为是调用成功
    if (detail.groupNo) {
      //如果第一个团员的Id和当前用户Id一致则认为当前用户是团长
      this.isMyCreate = detail.firstGroupMemberId === this.$store.$storeUser.UserInfo.id
      let dayTime = 24 * 1000 * 60 * 60 // 一天多少毫秒
      let curtime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
      let groupBuyEndTime = detail.signUpEndTime - dayTime
      // 拼团的创建时间加24个小时计算结束时间
      this.endTime = detail.createTime + dayTime
      /**
       * 因为报名结束后仍有团处于等待成团状态
       * 这种情况下拼团倒计时根据报名结束时间来计算
       */
      if (this.endTime > groupBuyEndTime) this.endTime = groupBuyEndTime
      /**
       * Q: 因为团失效检测为一个小时跑一次, 所以团实际状态跟后端返回数据存在差异
       * A: 这里通过时间计算实际状态，且根据用户不同对数据做相应调整，具体调整↓↓↓
       * ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
       * 1. 如果是团长进入且拼团状态为“待成团”则倒计时结束35分钟后切换为已失效
       * 2. 如果是其他用户进入且拼团状态为“待成团”则立即切换为已失效
       */
      if (detail.status === 0 && (this.isMyCreate ? curtime - this.endTime > 2100000 : this.endTime <= curtime)) detail.status = 2
      // 如果（待成团或者拼团失败团长进入）并且倒计时不为零时才显示倒计时
      this.showDownTime = detail.status === 0 || (detail.status === 2 && this.isMyCreate)
      // 如果拼团为待成团则倒计时结束后需要刷新页面
      this.resetRefresh = detail.status === 0
      // 如果需要显示倒计时再开启倒计时的计算
      if (this.showDownTime) {
        // 开始倒计时
        // this.endTime 有值后才能调用该函数
        this.downtime()
      }
      let agrDataSource: any = await this.$store.$storeCourse.onGetCourseProtos(this.productId)
      this.agrDataSource = agrDataSource
      this.detail = detail
      this.loading = false
    }
  }
}
