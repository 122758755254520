/**
 * 新版订单详情
 * author mengxd
 * 2022.6.17
 *
 * **/

import {
  PaymentTypesNumber,
  PaymentTypes,
  BuyCourseTypeNumber,
  BuyCourseType,
  EnumGroupStatus,
  refundStatusNumber,
  EnumGiftStatus
} from '@xt/client/enums'
import { Observer } from 'mobx-vue'
import { Vue, Component } from 'vue-property-decorator'
import Order from '../../entities/basics/order'
import lodash from 'lodash'
import { Course } from '@xt/client/entities/basics/course'
import { EnumApiCourse, EnumApiProduct } from '@xt/client/api'

@Observer
@Component
export class OrderDetails extends Vue {
  dataSource: Order = new Order({} as any)

  get PageStore() {
    return this.$store.$storeOrder
  }

  get Detail() {
    return this.PageStore.orderDetail
  }

  get UserPageStore() {
    return this.$store.$storeUser
  }

  get orderNo() {
    return this.dataSource.origin.orderNo
  }

  get refundOrderNo() {
    return this.dataSource.origin.refundOrderSn
  }

  get isMyGive() {
    return this.dataSource.origin.memberId === this.$store.$storeUser.UserInfo.id && this.dataSource.isGive
  }
  /* 是否展示退款截至日期 */
  get isShowRefundEndTime() {
    return !(this.dataSource.isCancelled || this.dataSource.isNotPay || this.isMyGive)
  }
  /* 发起退款 */
  get startRefund() {
    return (
      this.dataSource.origin.refundStatus == refundStatusNumber.ApprovalPending ||
      this.dataSource.origin.refundStatus == refundStatusNumber.refunding
    )
  }
  /* 退款成功 */
  get isRefunded() {
    return this.dataSource.origin.refundStatus == refundStatusNumber.refundSuccess
  }
  /** 使用桐板儿书 **/
  get coinNumber() {
    return Math.ceil(this.dataSource.origin.virtualCurrency / 100 / 8)
  }
  /**
   * 获取桐板儿实际支付金额
   */
  get getCoinPracticalPayPrice() {
    // 桐板儿支付金额 > 订单原价  （纯桐板儿支付）
    if (this.dataSource.origin.virtualCurrency / 100 > this.dataSource.origin.orderAmount / 100) {
      return this.dataSource.origin.orderAmount / 100
    }
    return this.dataSource.origin.virtualCurrency / 100
  }
  /** 实付金额 **/
  get payAmount() {
    return this.dataSource.origin.payAmount / 100
  }
  /** 商品金额 **/
  get productPrice() {
    if (this.dataSource.origin.orderAmount) {
      return this.dataSource.origin.orderAmount / 100
    }
    return 0
  }

  get isHasBuy() {
    return !!Course.UserHasbuyGroup.filter(
      x => x.productId === this.dataSource.origin.productId || x.productId === this.dataSource.origin.pkgId
    )[0]
  }
  /**
   * 是否可以购买
   */
  get canSigup() {
    let currentTime = this.$ajax.serviceDate.valueOf()
    return (
      (this.dataSource.origin.signUpEndTime > currentTime || this.dataSource.origin.turnPkgEndTime > currentTime) &&
      this.dataSource.origin.publishStatus === 2 &&
      this.dataSource.origin.canSignUp
    )
  }

  get canReSignup() {
    return (this.dataSource.isHasRefund || this.dataSource.isCancelled) && !this.isHasBuy && this.canSigup
  }

  get showBtmFixed() {
    return !(
      (this.dataSource.isCancelled && !this.canReSignup) ||
      (this.isMyGive && this.startRefund) ||
      (this.isRefunded && !this.canReSignup)
    )
  }

  /**
   * 是否拼团失败
   */
  get showGroupFailSeal() {
    // return this.dataSource.productInfo.isOpenGroup && this.dataSource.origin.status === EnumGroupStatus.Fail
    // 拼团订单 & 拼团失败
    // return this.isGroup && this.dataSource.origin.status === EnumGroupStatus.Fail
    if (this.isGroup) {
      return this.dataSource.origin.status === EnumGroupStatus.Fail || this.dataSource.isCancelled
    }
    return false
  }

  /**
   * 是否拼团成功
   */
  get showGroupSuccessSeal() {
    // return this.dataSource.productInfo.isOpenGroup && this.dataSource.origin.status === EnumGroupStatus.Success
    // 拼团订单 & 拼团成功
    return this.isGroup && !this.dataSource.isCancelled && this.dataSource.origin.status === EnumGroupStatus.Success
  }

  /**
   * 未领取
   */
  get showGiveWaitSeal() {
    // 赠课人 & （未领取 | 已拒绝）
    return (
      this.isMyGive &&
      (this.dataSource.origin.receiveStatus === EnumGiftStatus.Wait || this.dataSource.origin.receiveStatus === EnumGiftStatus.Fail)
    )
  }

  /**
   * 已领取
   */
  get showGiveFailSeal() {
    // 赠课人 & （已领取）
    return this.isMyGive && this.dataSource.origin.receiveStatus === EnumGiftStatus.Success
  }

  /**
   * 是否是拼团订单
   */
  get isGroup() {
    return this.dataSource.productInfo.isOpenGroup
  }

  get isFree() {
    return this.dataSource.productInfo.origin.originalPrice === 0
  }

  /** 是否是随报随学 */
  get isSignupAndLearn() {
    return this.dataSource.origin.signUpEndTime === 2147000000000
  }

  // 支付信息
  get payInfoList() {
    let payInfoList = []
    let subAry = []
    // 订单编号
    let orderNoItem = lodash.merge({ cunstomClass: '' }, this.orderNoItem)

    // 正常购买
    if (this.dataSource.buyWay === 1 && !this.isGroup) {
      // 已取消状态
      if (this.dataSource.isCancelled) {
        payInfoList = []
        subAry = [orderNoItem, this.buyWayItem]
        payInfoList.push(subAry)
        subAry = [this.createTime, this.cancelTime]
        payInfoList.push(subAry)
      }
      // 待支付状态
      else if (this.dataSource.isNotPay) {
        payInfoList = []
        subAry = [orderNoItem, this.buyWayItem]
        payInfoList.push(subAry)
        subAry = [this.createTime]
        payInfoList.push(subAry)
      }
      // 已支付状态 || 发票开具中 || 发票已开具 || 申请退款（退款中） || 已退款 || 待确认
      // 6.30去掉ToBeConfirmed
      else if (
        this.dataSource.isHasPay ||
        this.dataSource.isWaitInvoice ||
        this.dataSource.isHasInvoice ||
        this.dataSource.isRefund ||
        this.dataSource.isHasRefund
        // this.dataSource.isToBeConfirmed
      ) {
        payInfoList = []
        subAry = [orderNoItem, this.paymentTypeItem]
        payInfoList.push(subAry)
        subAry = this.getCoin.value ? [this.buyWayItem, this.getCoin] : [this.buyWayItem]
        payInfoList.push(subAry)
        subAry = [this.createTime, this.payTime]
        payInfoList.push(subAry)
      }
    }
    // 赠课
    else if (this.dataSource.isGive) {
      // 赠课人
      if (this.isMyGive) {
        // 取消订单
        if (this.dataSource.isCancelled) {
          payInfoList = []
          subAry = [orderNoItem, this.buyWayItem, this.giveSomeBody]
          payInfoList.push(subAry)
          subAry = [this.createTime, this.cancelTime]
          payInfoList.push(subAry)
        }
        // 待支付状态
        else if (this.dataSource.isNotPay) {
          payInfoList = []
          subAry = [orderNoItem, this.buyWayItem, this.giveSomeBody]
          payInfoList.push(subAry)
          subAry = [this.createTime] //, this.cancelTime
          payInfoList.push(subAry)
        }
        // 已支付状态 || 退款中 || 已退款 || 待确认
        // 6.30去掉ToBeConfirmed
        else if (
          this.dataSource.isHasPay ||
          this.dataSource.isRefund ||
          this.dataSource.isHasRefund /** this.dataSource.isToBeConfirmed */
        ) {
          payInfoList = []
          subAry = [orderNoItem, this.paymentTypeItem]
          payInfoList.push(subAry)
          subAry = [this.buyWayItem, this.refundEndTime]
          payInfoList.push(subAry)
          subAry = this.getCoin.value ? [this.giveSomeBody, this.getCoin] : (subAry = [this.giveSomeBody])
          payInfoList.push(subAry)
          subAry = [this.createTime, this.payTime]
          if (this.receiveTime) {
            subAry.push(this.receiveTime)
          }
          payInfoList.push(subAry)
        }
      }
      // 被赠课人
      else {
        // 已支付 || 发起退款 || 已退款 || 待确认
        // 6.30去掉ToBeConfirmed
        if (this.dataSource.isHasPay || this.dataSource.isRefund || this.dataSource.isHasRefund /** this.dataSource.isToBeConfirmed */) {
          payInfoList = []
          subAry = [orderNoItem]
          payInfoList.push(subAry)
          subAry = [this.receiveGift]
          if (this.receiveTime) {
            subAry.push(this.receiveTime)
          }
          payInfoList.push(subAry)
        }
      }
    }
    // 拼团
    else if (this.isGroup) {
      // 取消订单
      if (this.dataSource.isCancelled) {
        payInfoList = []
        subAry = [orderNoItem, this.buyWayItem, this.groupPerson]
        payInfoList.push(subAry)
        subAry = [this.createTime, this.cancelTime]
        payInfoList.push(subAry)
      }
      // 待支付状态
      if (this.dataSource.isNotPay) {
        payInfoList = []
        subAry = [orderNoItem, this.buyWayItem, this.groupPerson]
        payInfoList.push(subAry)
        subAry = [this.createTime]
        payInfoList.push(subAry)
      }
      // 已支付状态 || 申请退款（退款中） || 已退款
      else if (this.dataSource.isHasPay || this.dataSource.isRefund || this.dataSource.isHasRefund) {
        payInfoList = []
        subAry = [orderNoItem, this.paymentTypeItem]
        payInfoList.push(subAry)
        subAry = [this.buyWayItem, this.groupPerson]
        payInfoList.push(subAry)
        if (this.getCoin.value) {
          subAry = [this.getCoin]
          payInfoList.push(subAry)
        }
        subAry = [this.createTime, this.payTime]
        payInfoList.push(subAry)
      }
    }
    return payInfoList
  }

  /**
   * 退款信息
   */
  get refundInfoList() {
    let refundInfo = []
    let subAry = []

    // 退款编号
    let refundOrderNoItem = lodash.merge({ cunstomClass: '' }, this.refundOrderNoItem)

    // 申请退款（退款中）

    if (this.dataSource.isRefund) {
      refundInfo = []
      subAry = [refundOrderNoItem, this.refundAmount]
      refundInfo.push(subAry)
      //桐板扣减 桐板返还   桐板补偿
      //memberId 下单用户id
      if (!(this.dataSource.origin.memberId != this.$store.$storeUser.UserInfo.id && this.dataSource.isGive)) {
        subAry = []
        if (this.coinDeduction.value > 0) {
          subAry.push(this.coinDeduction)
        }
        if (this.backCoins.value > 0) {
          subAry.push(this.backCoins)
        }
        if (this.coinCompensate && this.coinCompensate.show) {
          subAry.push(this.coinCompensate)
        }
        if (subAry.length) refundInfo.push(subAry)
      }
      subAry = [this.applyTime]
      refundInfo.push(subAry)
    }
    // 已退款
    else if (this.dataSource.isHasRefund) {
      refundInfo = []
      subAry = [refundOrderNoItem, this.refundAmount]
      refundInfo.push(subAry)
      if (!(this.dataSource.origin.memberId != this.$store.$storeUser.UserInfo.id && this.dataSource.isGive)) {
        subAry = []
        if (this.coinDeduction.value > 0) {
          subAry.push(this.coinDeduction)
        }
        if (this.backCoins.value > 0) {
          subAry.push(this.backCoins)
        }
        if (this.coinCompensate && this.coinCompensate.show) {
          subAry.push(this.coinCompensate)
        }
        if (subAry.length) refundInfo.push(subAry)
      }
      subAry = [this.applyTime, this.refundTime]
      refundInfo.push(subAry)
    }

    return refundInfo
  }

  /**
   * 订单编号
   */
  get orderNoItem() {
    return {
      type: 1,
      canCopy: true,
      label: '订单编号',
      value: this.orderNo
    }
  }

  /**
   * 支付方式
   */
  get paymentTypeItem() {
    return {
      type: 1,
      canCopy: false,
      label: '支付方式',
      value: lodash.result(PaymentTypes, lodash.get(PaymentTypesNumber, this.dataSource.origin.paymentType))
    }
  }

  /**
   * 购课类型
   */
  get buyWayItem() {
    return {
      type: 1,
      canCopy: false,
      label: '购课类型',
      value: this.isGroup ? '拉手团' : lodash.result(BuyCourseType, lodash.get(BuyCourseTypeNumber, this.dataSource.origin.buyWay))
    }
  }

  /**
   * 购课获得桐板儿
   */
  get getCoin() {
    const getCoin = this.dataSource.origin.payAmount / 100 / 100
    return {
      type: 1,
      canCopy: false,
      label: '购课获得桐板儿',
      value: getCoin >= 1 ? Math.round(getCoin) : 0,
      unit: '个'
    }
  }

  /**
   * 退款编号
   */
  get refundOrderNoItem() {
    return {
      type: 1,
      canCopy: true,
      label: '退款编号',
      value: this.refundOrderNo
    }
  }

  /**
   * 退款金额
   */
  get refundAmount() {
    return {
      type: 1,
      canCopy: false,
      label: '退款金额',
      value: this.dataSource.origin.refundAmount / 100,
      unit: '元'
    }
  }

  /**
   * 桐板儿扣减
   */
  get coinDeduction() {
    let shouldRefundAmount = this.dataSource.origin.shouldRefundAmount / 100
    let coinCompensateAmount = this.dataSource.origin.scoreCompensationAmount / 100
    let diff = (shouldRefundAmount - this.refundAmount.value - coinCompensateAmount) / 100
    diff = diff < 1 ? 0 : diff
    return {
      type: 1,
      canCopy: false,
      label: '桐板儿扣减',
      // value : coinDeduction >= 1 ? Math.round(coinDeduction) : 0,
      value: this.getCoin.value - Math.round(diff),
      unit: '个'
    }
  }

  /**
   * 桐板儿返还
   */
  get backCoins() {
    return {
      type: 1,
      canCopy: false,
      label: '桐板儿返还',
      value: Math.ceil(this.dataSource.origin.virtualCurrency / 100 / 8),
      unit: '个'
    }
  }

  /**
   * 桐板儿补偿
   */
  get coinCompensate() {
    let coinCompensate = this.dataSource.origin.scoreCompensationAmount
    return {
      type: 1,
      canCopy: false,
      label: '桐板儿补偿',
      value: `${coinCompensate / 100 / 8}个（${coinCompensate / 100}元）`,
      show: coinCompensate > 0 ? true : false
    }
  }

  /**
   * 赠给
   */
  get giveSomeBody() {
    return {
      type: 1,
      canCopy: false,
      label: '赠给',
      value: `${this.dataSource.origin.receiverNickname}（${
        this.dataSource.origin.receiverPhoneNum || this.dataSource.origin.receiverEmail
      }）`
    }
  }

  /**
   * 收到的礼物
   */
  get receiveGift() {
    // return {
    //   type : 3,
    //   icon: 'https://static.xuantong.cn/images/give_class_no.png',
    //   content: '收到的礼物，来自：',
    //   mobile : this.dataSource.origin.receiverPhoneNum,
    //   nickName : this.dataSource.origin.receiverNickname,
    //   cunstomClass: 'receive'
    // }
    return {
      type: 3,
      icon: 'https://static.xuantong.cn/images/give_class_no.png',
      content: `收到的礼物，来自：${this.dataSource.origin.memberNickname}`,
      cunstomClass: 'receive'
    }
  }

  get collageHand() {
    return this.dataSource.origin.firstGroupMemberId && this.dataSource.origin.otherGroupMemberId
      ? this.$store.$storeGroup.collageHandSuccessUrl
      : this.$store.$storeGroup.collageHandUrl
  }
  get firstHeaderImg() {
    return this.myCreate ? this.$store.$storeUser.UserInfo.id : this.dataSource.origin.firstGroupMemberId
  }
  get otherHeaderImg() {
    return this.myCreate
      ? this.dataSource.origin.otherGroupMemberId
        ? this.dataSource.origin.otherGroupMemberId
        : ''
      : this.$store.$storeUser.UserInfo.id
  }
  get firstGroupMemberId() {
    return this.dataSource.origin.firstGroupMemberId || this.$store.$storeUser.UserInfo.id
  }

  get myCreate() {
    // 是一个自动取消团购的订单  默认自己就是之前的团长
    if (this.isGroup && this.dataSource.origin.firstGroupMemberId === null) {
      return true
    }
    return this.dataSource.origin.groupHeader
  }
  get otherGroupMemberNickname() {
    if (this.showGroupSuccessSeal) {
      return this.myCreate ? this.dataSource.origin.otherGroupMemberNickname : this.dataSource.origin.firstGroupMemberNickname
    }
    //参团待支付，显示团长昵称
    if (!this.myCreate && this.dataSource.isNotPay) {
      return this.dataSource.origin.firstGroupMemberNickname
    }
    //参团待支付，显示团长昵称
    if (!this.myCreate && this.dataSource.isCancelled) {
      return this.dataSource.origin.firstGroupMemberNickname
    }
    return null
  }
  /**
   * 拼团人 && 参团人
   */
  get groupPerson() {
    return {
      type: 2,
      mainPhoto: this.firstHeaderImg,
      impactPhoto: this.otherHeaderImg,
      groupStatus: this.dataSource.origin.status,
      collageHand: this.collageHand,
      impactUserName: this.otherGroupMemberNickname,
      computedDataSource: this.dataSource,
      groupNo: this.dataSource.origin.groupNo,
      isNotPay: this.dataSource.isNotPay,
      productId: this.dataSource.origin.productId
    }
  }

  /**
   * 创建时间
   */
  get createTime() {
    return {
      type: 1,
      canCopy: false,
      label: '创建时间',
      value: this.dataSource.createOrderTimeText
    }
  }

  /**
   * 付款时间
   */
  get payTime() {
    return {
      type: 1,
      canCopy: false,
      label: '付款时间',
      value: this.dataSource.payOrderTimeText
    }
  }

  /**
   * 取消时间
   */
  get cancelTime() {
    return {
      type: 1,
      canCopy: false,
      label: '取消时间',
      value: this.dataSource.cancelOrderTimeText
    }
  }

  /**
   * 申请时间
   */
  get applyTime() {
    return {
      type: 1,
      canCopy: false,
      label: '申请时间',
      value: this.dataSource.refundCreateTime
    }
  }

  /**
   * 退款时间
   */
  get refundTime() {
    return {
      type: 1,
      canCopy: false,
      label: '退款时间',
      value: this.dataSource.refundFinishTime
    }
  }

  /**
   * 领取有效期
   */
  get refundEndTime() {
    return {
      type: 1,
      canCopy: false,
      label: '领取有效期',
      value: this.dataSource.refundEndTime
      // value :  this.dataSource.receiveTime
    }
  }

  /**
   * 领取时间
   */
  get receiveTime() {
    if (this.dataSource.receiveTime) {
      return {
        type: 1,
        canCopy: false,
        label: '领取时间',
        value: this.dataSource.receiveTime
      }
    }
    return null
  }

  /**
   * 符串
   */
  /**
   * 已经取消的赠课订单 &  退课已经截止的礼物 & 已取消的拼团 & 已取消的普通订单 不显示按钮组
   */
  // get showBtns() {
  //   return !this.dataSource.isCancelled
  //           && !(!this.isMyGive && this.dataSource.isGive && this.dataSource.isRefundEnd)
  //           && (
  //             !this.dataSource.productInfo.isOpenGroup
  //               || (this.dataSource.origin.status === EnumGroupStatus.Success || !this.dataSource.isRefundEnd)
  //           )
  // }

  /**
   * 显示退课相关信息
   * **/
  get showRefundMessage() {
    let res =
      !this.dataSource.isNotPay &&
      !this.dataSource.isCancelled &&
      !this.dataSource.isHasRefund &&
      (!this.dataSource.isGive || !this.isMyGive) &&
      (!this.dataSource.productInfo.isOpenGroup || this.dataSource.origin.status !== EnumGroupStatus.Fail)
    return res
  }
  /**
   * 显示订单倒计时
   * **/
  // get showTimeRoll(){

  //   return {
  //     type : 1,
  //     dataSource : this.dataSource
  //   }
  // }

  /**
   * 获取我购买过的课程
   */
  async onOwnHasbuyCourse() {
    try {
      const res: Array<number> = await this.$ajax.get(EnumApiCourse.CourseListHasbuy)
      // 自己买过的课程
      const hasbuyCourse: Array<any> = await this.$ajax.get(EnumApiProduct.productHasBuy)
      Course.onSetUserHasbuy(res, hasbuyCourse)
    } catch (error) {
      console.log(error)
    }
  }

  //主要是按钮那部分
  async onRefresh() {
    // this.Detail.onReset();

    this.dataSource = await this.Detail.onLoading({ orderNo: this.orderNo })
  }
  beforeDestroy() {}
  mounted() {}
}
