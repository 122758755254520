import { EnumGroupStatus, EnumGiftStatus, EnumOrderStateTypeNumber, EnumOrderState } from '@xt/client/enums'
import { Course } from '../../entities/basics/course'
import { Observer } from 'mobx-vue'
import moment from 'moment'
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import Order from '../../entities/basics/order'

@Observer
@Component
export default class OrderItemScript extends Vue {
  @Prop({}) dataSource: Order

  downtimeTimer: NodeJS.Timer
  timer: string | boolean = true

  get isHasBuy() {
    return !!Course.UserHasbuyGroup.filter(
      x => x.productId === this.dataSource.origin.productId || x.productId === this.dataSource.origin.pkgId
    )[0]
  }
  /**
   * 是否可以购买
   */
  get canSigup() {
    let currentTime = this.$ajax.serviceDate.valueOf()
    return (
      (this.dataSource.origin.signUpEndTime > currentTime || this.dataSource.origin.turnPkgEndTime > currentTime) &&
      this.dataSource.origin.publishStatus === 2 &&
      this.dataSource.origin.canSignUp
    )
  }

  get canReSignup() {
    return (this.dataSource.isHasRefund || this.dataSource.isCancelled) && !this.isHasBuy && this.canSigup
  }

  get PageStore() {
    return this.$store.$storeOrder
  }
  get Pagination() {
    return this.PageStore.Pagination
  }

  get orderNo() {
    return this.dataSource.origin.orderNo
  }
  get isMyGive() {
    return this.dataSource.origin.memberId === this.$store.$storeUser.UserInfo.id && this.dataSource.isGive
  }

  get orderAmount() {
    if (this.dataSource.origin.orderAmount === 0) return '免费'
    return this.dataSource.origin.orderAmount / 100 + '元'
  }

  /**
   * 获取桐板儿实际支付金额
   */
  get getCoinPayPrice() {
    // 桐板儿支付金额 > 订单原价  （纯桐板儿支付）
    if (this.dataSource.origin.virtualCurrency / 100 > this.dataSource.origin.orderAmount / 100) {
      return this.dataSource.origin.orderAmount / 100
    }
    return this.dataSource.origin.virtualCurrency / 100
  }

  get showRefundMessage() {
    return (
      !this.dataSource.isNotPay &&
      !this.dataSource.isCancelled &&
      !this.dataSource.isHasRefund &&
      (!this.dataSource.isGive || !this.isMyGive) &&
      (!this.dataSource.productInfo.isOpenGroup || this.dataSource.origin.status !== EnumGroupStatus.Fail)
    )
  }

  get isFree() {
    return this.dataSource.productInfo.origin.originalPrice === 0
  }

  /** 是否是随报随学 */
  get isSignupAndLearn() {
    return this.dataSource.origin.signUpEndTime === 2147000000000
  }

  get showSplitLine() {
    return this.dataSource.isGive || !this.dataSource.isCancelled || this.canReSignup || this.dataSource.productInfo.isOpenGroup
  }

  get showGroupFailSeal() {
    if (this.dataSource.productInfo.isOpenGroup) {
      return this.dataSource.origin.status === EnumGroupStatus.Fail || this.dataSource.isCancelled
    }
    return false
    // return this.dataSource.productInfo.isOpenGroup && (this.dataSource.origin.status === EnumGroupStatus.Fail || this.dataSource.origin.status === '')
  }

  get showGroupSuccessSeal() {
    return (
      this.dataSource.productInfo.isOpenGroup && !this.dataSource.isCancelled && this.dataSource.origin.status === EnumGroupStatus.Success
    )
  }

  get showGiveWaitSeal() {
    return (
      this.isMyGive &&
      (this.dataSource.origin.receiverStatus === EnumGiftStatus.Wait || this.dataSource.origin.receiverStatus === EnumGiftStatus.Fail)
    )
  }

  get showGiveFailSeal() {
    return this.isMyGive && this.dataSource.origin.receiverStatus === EnumGiftStatus.Success
  }

  /**
   * 已经取消的赠课订单 &  退课已经截止的未退款礼物礼物 & 已取消的拼团 & 已取消的普通订单 不显示按钮组
   */
  get hideBtns() {
    //如果不是团购类的，并且已取消的订单。按钮区域就隐藏；
    return (
      ((this.dataSource.isCancelled && this.dataSource.origin.activityType == 0) ||
        //已支付，且不是团购，不是我赠送的课程，且退款已截止
        (this.dataSource.isHasPay &&
          this.dataSource.origin.activityType == 0 &&
          !this.isMyGive &&
          (this.dataSource.isRefundEnd || !this.dataSource.origin.refund))) &&
      !this.canReSignup
    )
  }
  /**
   * 参团已取消印章显示
   * **/
  get cancelledByLeagueMember() {
    return (
      this.dataSource.isCancelled &&
      this.dataSource.origin.groupNo != '' &&
      this.dataSource.origin.firstGroupMemberId != this.$store.$storeUser.UserInfo.id
    )
  }

  downtime() {
    const endtime: number = this.dataSource.origin.createTime + 30 * 60 * 1000
    this.downtimeTimer = setInterval(() => {
      let currentTime = this.$ajax.serviceDate.valueOf()
      let diff = endtime - currentTime
      // Thu Jan 01 1970 08:00:00 GMT+0800 (中国标准时间) {} 所以 - 28800000
      this.timer = diff <= 0 ? false : moment(diff - 28800000).format('mm:ss')
      if (diff <= 0) {
        clearInterval(this.downtimeTimer)
        this.timer = false
        let orderOrigin = this.dataSource.origin
        orderOrigin.orderStatus = EnumOrderStateTypeNumber.Cancelled
        this.Pagination.onUpdate(this.dataSource.origin.orderNo, _ => new Order(orderOrigin))
      }
    }, 200)
  }

  created() {
    if (this.dataSource.isNotPay) this.downtime()
    else this.timer = false
  }

  toCourseDetail() {
    if (this.dataSource.origin.activityPageUrl.toString() != '') return this.$router.push({ path: this.dataSource.origin.activityPageUrl })
    this.$router.push({
      path: `/commodity/${this.dataSource.origin.productId.toString()}`
    })
  }

  @Emit('refresh')
  onRefresh() {}
}
