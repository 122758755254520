import { uuid } from '@xt/client/utils/uuid'
import { MouseTrack } from './mouse-track'
import { KeyboardTrack } from './keyboard-track'
import { VisibleTrack } from './visible-track'
import { AjaxBasics } from '@xt/client'
import { reportEntryLog } from '@xt/client/utils/log'

/**
 * 失焦
 * 键盘输入
 * 鼠标移动
 *
 * 几点到几点在移动 几点到几点在静止
 *
 * 阈值30s
 */

const FLUSH_INTERVAL = 5 * 60 * 1000
const PAUSE_THRESHOLD = 30 * 1000

class Track {
  private uuid: string
  private isRunning = false
  private mouseTrack: MouseTrack
  private keyboardTrack: KeyboardTrack
  private visibleTrack: VisibleTrack
  private flushInterval: null | ReturnType<typeof setInterval> = null
  private windowInfo = {
    width: window.outerWidth,
    height: window.outerHeight
  }
  private $ajax: AjaxBasics

  constructor() {
    this.uuid = uuid()
    this.mouseTrack = new MouseTrack(PAUSE_THRESHOLD)
    this.keyboardTrack = new KeyboardTrack(PAUSE_THRESHOLD)
    this.visibleTrack = new VisibleTrack()

    this.stop = this.stop.bind(this)
    this.sendLog = this.sendLog.bind(this)
  }

  private async sendLog(data: { mouse: Record<string, any>; keyboard: Record<string, any>; visible: Record<string, any> }) {
    try {
      if (this.$ajax) {
        this.$ajax.post('/moment/api/action/log', {
          ...data,
          uid: this.uuid,
          path: location.pathname,
          window: this.windowInfo
        })
      }
    } catch (e) {
      console.log(e)
      reportEntryLog('tracker.report.error', { e, uuid: this.uuid, path: location.pathname })
    }
  }

  record($ajax: AjaxBasics) {
    this.$ajax = $ajax
    if (this.isRunning) return

    this.isRunning = true

    this.mouseTrack.record()
    this.keyboardTrack.record()
    this.visibleTrack.record()

    // 定时五分钟清理一波数据
    this.flushInterval = setInterval(() => {
      const mouseFrames = this.mouseTrack.flush()
      const keyboardFrame = this.keyboardTrack.flush()
      const visibleFrame = this.visibleTrack.flush()

      // console.log(JSON.stringify(visibleFrame, undefined, 2))

      // console.log($ajax)
      // console.warn(
      //   JSON.stringify({
      //     mouse: mouseFrames,
      //     keyboard: keyboardFrame,
      //     visible: visibleFrame,
      //     uuid: this.uuid,
      //     path: location.pathname,
      //     window: this.windowInfo
      //   })
      // )
      this.sendLog({ mouse: mouseFrames, keyboard: keyboardFrame, visible: visibleFrame })
    }, FLUSH_INTERVAL)

    window.addEventListener('beforeunload', this.stop)
  }

  stop() {
    this.mouseTrack.stop()
    this.keyboardTrack.stop()
    this.visibleTrack.stop()

    if (this.flushInterval) {
      clearInterval(this.flushInterval)
      this.flushInterval = null
    }
  }

  export() {}

  addEventListener(callback: (eventType: 'mousemove' | 'mousepause' | 'keyboardinput' | 'keyboardpause') => void) {
    this.mouseTrack.addEventListener(callback)
    this.keyboardTrack.addEventListener(callback)
  }
}

const track = new Track()
;(window as any).track = track
export default track
