import { AjaxBasics } from '@xt/client'

type VisibleFrame = {
  type: 'hidden' | 'visible'
  begin: number
  end: number
  duration: number
}

export class VisibleTrack {
  private isRecording = false
  private frames: VisibleFrame[] = []
  private lastTrackTime: number | null = null
  private chunk: number = 0

  constructor() {
    this.lastTrackTime = this.getServerTime()
    // console.log('begin', this.lastTrackTime)
    this.hanldeVisibilityChange = this.hanldeVisibilityChange.bind(this)
    this.stop = this.stop.bind(this)
  }

  private getServerTime() {
    return AjaxBasics.serviceDate.valueOf()
  }

  private appendFrame(eventType: 'hidden' | 'visible', begin: number, end: number) {
    this.frames.push({ type: eventType, begin, end, duration: end - begin })
  }

  private hanldeVisibilityChange() {
    const now = this.getServerTime()

    const currntState = document.visibilityState
    // console.warn(currntState, now)

    this.appendFrame(currntState === 'visible' ? 'hidden' : 'visible', this.lastTrackTime, now)

    this.lastTrackTime = now
  }

  record() {
    if (this.isRecording) return

    this.isRecording = true

    document.addEventListener('visibilitychange', this.hanldeVisibilityChange)

    window.addEventListener('beforeunload', this.stop)
  }

  stop() {
    this.isRecording = false

    document.removeEventListener('visibilitychange', this.hanldeVisibilityChange)
  }

  export() {
    return this.frames
  }

  flush() {
    const result: Record<string, any> = {}
    const actionFrames = this.frames.filter(item => item.type === 'visible')
    const pauseFrames = this.frames.filter(item => item.type === 'hidden')
    const actionTotal = actionFrames.reduce((prev, current) => {
      return prev + current.duration
    }, 0)
    const pauseTotal = pauseFrames.reduce((prev, current) => {
      return prev + current.duration
    }, 0)
    const total = actionTotal + pauseTotal

    result.actionTotal = actionTotal
    result.pauseTotal = pauseTotal
    result.total = total
    result.frame = [...this.frames]
    result.chunk = this.chunk

    this.chunk = this.chunk + 1
    this.frames = []
    return result
  }
}
