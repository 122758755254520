

















































































import { Component, Prop, Vue, Provide, Inject, Watch } from 'vue-property-decorator'
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import Fab from './views/fab.vue'
import lodash, { template } from 'lodash'
import { Observer } from 'mobx-vue'
import GiftModal from './views/giftModal.vue'
import Header from './views/header.vue'
import MSAlert from './views/msAlert.vue'
import LayoutFooter from './views/footer.vue'
import Breadcrumb from './views/breadcrumb.vue'
import WX from '@xt/client/mixins/login/weixin'
import WXBindPopup from '@xt/www/components/popup/bindWX/index.vue'
import WXVerifyPopup from '@xt/www/components/popup/verifyWX/index.vue'
import AccountAlert from '@xt/www/components/accountAlert/index.vue'
import { giftCache } from '@xt/client/helpers'
import track from '@xt/client/utils/tracker'
// import xtChanner_asdew_asd23 from "@xt/client/entities/user/channel"
@Observer
@Component({
  components: {
    Fab,
    MSAlert,
    GiftModal,
    Header,
    Breadcrumb,
    LayoutFooter,
    WXBindPopup,
    WXVerifyPopup,
    AccountAlert
  }
})
export default class extends Vue {
  keyboardStatus: 'keyboardinput' | 'keyboardpause' = 'keyboardpause'
  mouseStatus: 'mousemove' | 'mousepause' = 'mousepause'
  visibleStatus: 'hidden' | 'visible' = 'visible'
  keyboardStart = Date.now()
  mouseStart = Date.now()
  keyboardTick = 0
  mouseTick = 0

  get config() {
    return {
      autoInsertSpaceInButton: false,
      locale: { zh: zh_CN, en: null }[this.$store.$locale.locale]
    }
  }
  get pageClass() {
    return 'xt-page-' + this.$route.name
  }
  get production() {
    return this.$store.$global.production
  }
  get keepAlive() {
    return !this.$store.$global.dev
  }
  get version() {
    return 'Version: ' + this.$store.$global.version + ', Build Time: ' + this.$store.$global.timestamp
  }
  get locale() {
    return this.$EnumLocaleLinks
  }
  get PageStore() {
    return this.$store.$storeUser
  }

  showPopup(key) {
    const value = giftCache.get(key)
    if (value) {
      this.PageStore.removeUniformGift()
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    }
    return !!value
  }

  showGiftPopup(item) {
    const value = giftCache.get(`gift-${item.orderNo}`)
    if (value) {
      this.PageStore.removeOrderGifts(item.id)
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    }
    return !!value
  }

  closeWXBindHandler() {
    this.PageStore.hideWeiXinBind()
  }

  closeWXVerifyHandler() {
    this.PageStore.hideWeiXinVerify()
  }

  async onOutLogin() {
    await this.PageStore.onOutLogin()
    window.location.replace(this.$router.resolve('/').href)
  }

  goTenYear() {
    window.open('https://mp.weixin.qq.com/s/PuFhSFFrhCfOQXsffZaJ-w')
  }

  hasGifts(list) {
    return !lodash.isEmpty(list)
  }

  keepAliveProps = {
    include: ['PageIndex', 'PageVideo', 'PageStationery', 'PageCourse', 'PageAbout']
  }
  mousewheel(e, b) {
    e.stopPropagation()
  }
  stopPropagation(e) {
    e.stopPropagation()
  }
  wxAuth() {
    !this.$store.$global.isMobilePage && WX.wxAuth()
  }
  @Watch('$route')
  RouteUpdate(to, from, next) {
    let fromMy = from.fullPath.startsWith('/my')
    let toMy = to.fullPath.startsWith('/my')
    let toMyPoints = to.fullPath.startsWith('/my/points')
    let toMyInfo = to.fullPath.startsWith('/my/information')
    let loggedIn = this.$store.$storeUser.loggedIn
    if (((!fromMy && toMy) || toMyPoints) && loggedIn && !toMyInfo) this.$store.$storeUser.onUpdatingUserInfo()
    this.wxAuth()
  }

  handleTrackDebug(type: 'mousemove' | 'mousepause' | 'keyboardinput' | 'keyboardpause') {
    if (type === 'mousemove' || type === 'mousepause') {
      if (type === 'mousemove') {
        this.mouseStart = Date.now()
      }
      this.mouseStatus = type
    } else if (type === 'keyboardinput' || type === 'keyboardpause') {
      if (type === 'keyboardinput') {
        this.keyboardStart = Date.now()
      }
      this.keyboardStatus = type
    }
  }

  mounted() {
    track.record(this.$ajax)
    track.addEventListener(this.handleTrackDebug)
    setInterval(() => {
      this.keyboardTick = Date.now() - this.keyboardStart
      this.mouseTick = Date.now() - this.mouseStart
    }, 1000)

    this.wxAuth()
    try {
      const xtChanner_asdew_asd23 = new BroadcastChannel('xtChanner_asdew_asd23')
      xtChanner_asdew_asd23.onmessage = async ev => {
        await this.$store.$storeUser.onOutLogin()
        if (this.$route.fullPath === '/') return (window.location.href = window.location.href)
        this.$router.replace('/')
      }
    } catch (e) {
      console.log(e)
    }
  }
  resetUniformGiftHandler() {
    this.PageStore.onResetUniformGift()
  }
  orderGiftHandler() {
    this.PageStore.onResetGiftPopup()
  }
  created() {
    window.localStorage.setItem('history-state-key', window.history.state.key)
    window.addEventListener('storage', async e => {
      if (e.key === 'message' && e.newValue.indexOf('logout') != -1) {
        await this.onOutLogin()
      }
    })
  }
  isComplete(title) {
    return lodash.eq(this.locale.links_complete, title)
  }
  get hideAll() {
    return this.$route.name === 'groupbuy-groupNo'
  }
}
