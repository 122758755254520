






















































import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import moment from 'moment'
import { EnumProductTypeQuery } from '@xt/client/enums'
import LazyImg from '@/components/lazyImg/index.vue'
import ButtonGroup from '../components/buttonGroup.vue'

@Observer
@Component({
  components: { LazyImg, ButtonGroup }
})
export default class PageView extends Vue {
  @Prop({}) dataSource
  get giveawayInfo() {
    return this.$store.$storeCourse.Details.giveawayInfo
  }
  // 6.30
  get giveawayCourse() {
    return this.giveawayInfo?.ids.some(item => `${item.giveawayProductId}` === this.$route.params.id)
  }
  get EnumProductTypeQuery() {
    return EnumProductTypeQuery
  }
  get activityType() {
    return this.dataSource.productInfo.activityType.toString(2)
  }
  get isOpenGroup() {
    return this.activityType ? !!parseInt(this.activityType[this.activityType.length - 1]) : false
  }
  get learnTimeInfo() {
    // 自习课
    if (this.dataSource.courseInfo?.permanent) {
      return '随报随学'
    }
    return `${moment(this.learnTime.learnStartTime).format('YYYY-MM-DD')} 至 ${moment(this.learnTime.learnEndTime).format('YYYY-MM-DD')}`
  }
  get reduceMoney() {
    return (this.dataSource.productInfo.originalPrice - this.dataSource.productInfo.discountPrice) / 100
  }
  get giveawayTagContent() {
    return `「线的天地」中国画线描基础课程系列，共包含4个课程。\n系列一，线的初识：书法用笔练习，写出圆、通、厚的中锋线条。\n系列二，“用”好每一根线条：体会线条间的变化，营构线条间的节奏感与配合关系。\n系列三，构图与造形：通过临摹，感受古人的用笔，深入学习如何布设画面。\n系列四，独立创作：对前三个阶段学习的总结，实现由认知到实操的转换。`
  }
  get coinDesc() {
    const entryKey = this.dataSource.productInfo.onlyEntryKey
    return `桐板儿是暄桐教室为老同学准备的小福利。线上课同学曾经报名支付的学费都将转化为桐板儿积分，并可在之后的课程报名中作为现金抵扣。100元=1个桐板儿，支付新学费时，一个桐板儿=8元。${
      ((entryKey === EnumProductTypeQuery.DayCourse || entryKey === EnumProductTypeQuery.SelfStudy) &&
        `(本次课程订单支付时，最多可使用${this.dataSource.productInfo.maxCoin}个桐板儿)`) ||
      ''
    }`
  }
  get productNum() {
    const entryKey = this.dataSource.productInfo.onlyEntryKey
    const count = this.dataSource.productInfo.bizProdCount
    if (entryKey === EnumProductTypeQuery.Course) {
      return {
        title: `共${count}课，${count}次助教评阅`,
        desc: `课程共${count}节课，助教老师每节课在线评阅作业一次。`
      }
    } else if (entryKey === EnumProductTypeQuery.DayCourse) {
      return {
        title: `共${count}天`,
        desc: `林曦老师监制，在${count}天里进行针对性精练，细致分拆重点和难点，给出有效的练习方法，每一步都有引领和陪伴。`
      }
    } else if (entryKey === EnumProductTypeQuery.SelfStudy) {
      return {
        title: `共${count}天`,
        desc: `林曦老师监制，在${count}天里进行循序渐进的练习，细致拆分要点，给出有效的练习方法，帮助大家自主学习。`
      }
    } else {
      return {
        title: `共${count + 2}关${
          (this.dataSource.courseInfo.needReviewCount && `，${this.dataSource.courseInfo.needReviewCount}次助教评阅`) || ''
        }`,
        desc: '同学将通过备展学习、提交练习习作、提交正式习作共三个步骤完成课程。'
      }
    }
  }
  get validDate() {
    const entryKey = this.dataSource.productInfo.onlyEntryKey
    if (entryKey === EnumProductTypeQuery.Course) {
      return {
        title: `直播课（${this.dataSource.courseInfo.defaultPlaybackDuration}天回放）`,
        desc: `林曦老师以线上直播的形式亲授。课程视频不提供下载，每次直播后的${this.dataSource.courseInfo.defaultPlaybackDuration}天内可无限次观看和复习当次的课程，逾期则无法再回看。`,
        isHide: !this.dataSource.courseInfo.defaultPlaybackDuration
      }
    } else if (entryKey === EnumProductTypeQuery.DayCourse || entryKey === EnumProductTypeQuery.SelfStudy) {
      // 教练日课和自习课区分随报随学场景
      if (this.dataSource?.courseInfo?.permanent) {
        return {
          color: 'purple',
          iconfont: 'iconexh_ic_validity',
          title: `永久有效`,
          desc: `报名后，同学可以无限次观看和复习课程。`
        }
      } else {
        return {
          title: `${this.dataSource.courseInfo.validTime}年内有效`,
          desc: `自开课后${this.dataSource.courseInfo.validTime}年时间内，同学可以无限次观看和复习课程。`
        }
      }
    } else {
      return {
        title: `${this.dataSource.courseInfo.validDate}年内有效`,
        desc: `自开课后${this.dataSource.courseInfo.validDate}年时间内，“备展学习”内容同学可以无限次观看和复习；提交“练习习作”和“正式习作”的时间段，以课程详情公布日期为准。`
      }
    }
  }
  get learnTime() {
    const entryKey = this.dataSource.productInfo.onlyEntryKey
    let learnStartTime = null
    let learnEndTime = null
    if (entryKey === EnumProductTypeQuery.Course) {
      learnStartTime = this.dataSource.courseInfo.learnStartTime
      learnEndTime = this.dataSource.courseInfo.learnEndTime
    } else if (entryKey === EnumProductTypeQuery.DayCourse || entryKey === EnumProductTypeQuery.SelfStudy) {
      learnStartTime = this.dataSource.courseInfo.studyStartTime
      learnEndTime = this.dataSource.courseInfo.studyEndTime
    } else {
      learnStartTime = this.dataSource.courseInfo.readyStartTime
      learnEndTime = this.dataSource.courseInfo.readyEndTime
    }
    return { learnStartTime, learnEndTime }
  }
  get studyDesc() {
    const entryKey = this.dataSource.productInfo.onlyEntryKey
    if (entryKey === EnumProductTypeQuery.Course) {
      return `学习时间内，可下载相关资料、提交作业以及晒作业`
    } else if (entryKey === EnumProductTypeQuery.DayCourse) {
      return `学习时间内，可查看课程内容、提交作业打卡`
    } else if (entryKey === EnumProductTypeQuery.SelfStudy) {
      return `报名后，可查看课程内容、提交作业打卡`
    } else {
      return `备展时间内，可查看课程内容、交作业闯关`
    }
  }
  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
